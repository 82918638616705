<template>
  <el-row class="table">
    <span class="title">物品出入库记录</span>
    <el-row class="row-bg">
      <el-input
        placeholder="输入物品名称"
        v-model.trim="inputVal"
        class="tb-sm-input"
        @keyup.enter.native="search"
      ></el-input>
      <el-button type="primary" size="medium" @click="search" class="tb-button">查 询</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
      <el-button type="info" size="medium" @click="add" class="tb-button">新增入库</el-button>
    </el-row>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list mt-15"
    >
      <el-table-column label="名称" align="center" prop="goodsTitle"></el-table-column>
      <el-table-column label="单位" align="center" prop="goodsUnit" width="150"></el-table-column>
      <el-table-column label="出入库" align="center" prop="cause"></el-table-column>
      <el-table-column label="数量" align="center" prop="amount" width="150"></el-table-column>
      <el-table-column label="变动时间" align="center" prop="createTime"></el-table-column>
      <el-table-column label="备注" align="center" prop="remark"></el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!--  -->
    <add-storage :show="goodsDialog" @close="goodsDialog=false" @updateView="getListTurnover"></add-storage>
  </el-row>
</template>

<script>
import { getListTurnover } from '@/api/three-planning'
import addStorage from '@/components/system/addStorage'

export default {
  components: { addStorage },
  data () {
    return {
      inputVal: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      goodsDialog: false,
      goodsData: null
    }
  },
  created () {
    this.getListTurnover()
  },
  methods: {
    async getListTurnover () {
      const res = await getListTurnover({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        goodsTitle: this.inputVal ? this.inputVal : ''
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    search () {
      this.pagination.currentPage = 1
      this.getListTurnover()
    },

    reset () {
      this.inputVal = ''
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListTurnover()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListTurnover()
    },

    add () {
      this.goodsDialog = true
    },

    edit (row) {
      this.goodsData = row
      this.goodsDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/table.scss'
</style>
