<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
    >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>新增入库</span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="选择物品：" prop="id">
        <el-select v-model="formData.id" filterable placeholder="请选择">
          <el-option
            v-for="item in goodsOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入库数量：" prop="amount">
        <el-input-number v-model="formData.amount" :min="0" label="请填写初始数量"></el-input-number>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { goodsChangeAmount, getGoodsList } from '@/api/three-planning'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      formData: {},
      goodsOptions: [],
      rules: {
        id: [{ required: true, message: '请选择物品', trigger: 'blur' }],
        amount: [{ required: true, message: '请填写入库数量', trigger: 'blur' }]
      }
    }
  },
  methods: {
    openDialog () {
      this.formData = {
        title: '',
        amount: 0
      }
      this.getGoodsList()
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    async getGoodsList () {
      const res = await getGoodsList({
        pageNum: 1,
        pageSize: 1000
      })
      this.goodsOptions = Object.assign([], res.body.list)
    },

    saveRoleForm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          goodsChangeAmount({
            ...this.formData
          }).then(res => {
            if (res.state === 'success') {
              this.close()
              this.$emit('updateView')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/dialog.scss';
</style>
