var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("物品出入库记录")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "输入物品名称" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.inputVal,
              callback: function ($$v) {
                _vm.inputVal = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "inputVal",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.search },
            },
            [_vm._v("查 询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.reset },
            },
            [_vm._v("重 置")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "info", size: "medium" },
              on: { click: _vm.add },
            },
            [_vm._v("新增入库")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list mt-15",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "名称", align: "center", prop: "goodsTitle" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "单位",
              align: "center",
              prop: "goodsUnit",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "出入库", align: "center", prop: "cause" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "数量",
              align: "center",
              prop: "amount",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "变动时间", align: "center", prop: "createTime" },
          }),
          _c("el-table-column", {
            attrs: { label: "备注", align: "center", prop: "remark" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("add-storage", {
        attrs: { show: _vm.goodsDialog },
        on: {
          close: function ($event) {
            _vm.goodsDialog = false
          },
          updateView: _vm.getListTurnover,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }